import { $authHost } from '@/API'
import {
	ICollectingSessionWithPagination,
	ICollectingSession,
	ICollectingSessionParams,
	IDtoPostCollectingSession,
	IPrestartCollectingResponse,
	IDtoPutCollectingSession
} from '@/types/collectingSession.types'

class CollectingSessionService {
	getAll = (params?: ICollectingSessionParams) => {
		return $authHost.get<ICollectingSessionWithPagination>(
			'api/collecting_session/',
			{
				params
			}
		)
	}

	getById = (params?: ICollectingSessionParams) => {
		return $authHost.get<ICollectingSession>('api/collecting/', {
			params
		})
	}

	getAllZones = (params?: ICollectingSessionParams) => {
		return $authHost.get<ICollectingSession[]>('api/collecting/all_zones/', {
			params
		})
	}

	create = (data: IDtoPostCollectingSession) => {
		return $authHost.post<IPrestartCollectingResponse>('api/collecting/', data)
	}

	update = (data: IDtoPutCollectingSession) => {
		return $authHost.put<ICollectingSession>(
			'api/collecting_session/' + data.id,
			data
		)
	}
}

export default new CollectingSessionService()
